// src/components/dashboard/tabs/SandboxCard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress,
    Tooltip,
    Avatar
} from '@mui/material';
import {
    Share2,
    Trash2,
    ArrowUpRight,
    UserCheck
} from 'lucide-react';
import { startCamundaProcess } from '../../../services/apiService';

const SandboxCard = ({
                         sandbox,
                         isOwner,
                         onShare,
                         onDelete,
                         token,
                         setError
                     }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Состояние для диалога подтверждения удаления
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Обработчик удаления песочницы
    const handleDeleteSandbox = async () => {
        try {
            setIsDeleting(true);
            await startCamundaProcess(
                token,
                'delete',
                sandbox.owner?.id,
                'delete',
                sandbox.id
            );
            setIsDeleting(false);
            setDeleteConfirmOpen(false);

            if (onDelete) {
                onDelete(sandbox.id);
            }
        } catch (err) {
            setIsDeleting(false);
            setError && setError(t('dashboard.sandboxes.error.deleting'));
            console.error('Error deleting sandbox:', err);
        }
    };

    return (
        <div className="p-4 rounded-lg border border-gray-200 hover:border-blue-300 hover:shadow-sm transition-all bg-white">
            <div className="flex justify-between items-start">
                <div>
                    <h3
                        className="font-medium text-gray-900 cursor-pointer hover:text-blue-600"
                        onClick={() => navigate(`/sandbox/${sandbox.id}`)}
                    >
                        {sandbox.name}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                        {sandbox.created_at && new Date(sandbox.created_at).toLocaleDateString()}
                    </p>
                </div>

                <div className="flex items-center gap-2">
                    <Tooltip title={t('dashboard.sandboxes.open')}>
                        <IconButton
                            size="small"
                            color="success"
                            onClick={() => navigate(`/sandbox/${sandbox.id}`)}
                        >
                            <ArrowUpRight className="w-5 h-5" />
                        </IconButton>
                    </Tooltip>

                    {isOwner && (
                        <>
                            <Tooltip title={t('dashboard.sandboxes.share')}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    onClick={() => onShare && onShare(sandbox)}
                                >
                                    <Share2 className="w-5 h-5" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={t('dashboard.sandboxes.delete')}>
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => setDeleteConfirmOpen(true)}
                                >
                                    <Trash2 className="w-5 h-5" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>

            {/* Метаданные песочницы */}
            <div className="mt-4 text-sm text-gray-500">
                {sandbox.owner_info && (
                    <p className="flex items-center">
                        <Avatar
                            sx={{ width: 20, height: 20, fontSize: '0.75rem', marginRight: '8px' }}
                            alt={sandbox.owner_info?.username || 'User'}
                        >
                            {sandbox.owner_info && sandbox.owner_info.username
                                ? sandbox.owner_info.username.charAt(0).toUpperCase()
                                : '?'}
                        </Avatar>
                        {t('dashboard.sandboxes.owner')}: {sandbox.owner_info.username}
                    </p>
                )}

                {sandbox.coworkers && sandbox.coworkers.length > 0 && (
                    <p className="flex items-center mt-1">
                        <UserCheck className="w-4 h-4 mr-2" />
                        {t('dashboard.sandboxes.shared_with', { count: sandbox.coworkers.length })}
                    </p>
                )}
            </div>

            {/* Диалог подтверждения удаления */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={() => !isDeleting && setDeleteConfirmOpen(false)}
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.sandboxes.delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.sandboxes.delete_confirm', { name: sandbox.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteConfirmOpen(false)}
                        disabled={isDeleting}
                    >
                        {t('dashboard.sandboxes.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteSandbox}
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        startIcon={isDeleting ? <CircularProgress size={20} /> : <Trash2 size={16} />}
                    >
                        {isDeleting
                            ? t('dashboard.sandboxes.deleting')
                            : t('dashboard.sandboxes.delete')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SandboxCard;