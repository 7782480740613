// src/components/dashboard/tabs/TemplateCard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Tooltip,
    Avatar
} from '@mui/material';
import {
    Play,
    Share2,
    Trash2,
    Server,
    CheckCircle,
    AlertTriangle,
    Loader
} from 'lucide-react';
import {
    deployTemplate,
    deleteTemplate
} from '../../../services/apiService';
import ShareTemplateDialog from './ShareTemplateDialog';

const TemplateCard = ({
                          template,
                          token,
                          setError,
                          onDeleted
                      }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Проверка на владельца
    const [isOwner, setIsOwner] = useState(false);

    // Диалоги
    const [deployConfirmOpen, setDeployConfirmOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [isDeploying, setIsDeploying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Определяем, владелец ли текущий пользователь
    useEffect(() => {
        if (template && template.created_by) {
            setIsOwner(true); // Здесь можно добавить проверку по ID текущего пользователя
        }
    }, [template]);

    // Функция развертывания шаблона
    const handleDeployTemplate = async () => {
        try {
            setIsDeploying(true);
            const response = await deployTemplate(token, template.id);
            setIsDeploying(false);
            setDeployConfirmOpen(false);
            navigate(`/sandbox/${response.sandbox_id}`);
        } catch (err) {
            setIsDeploying(false);
            setError && setError(t('dashboard.templates.deploy_error'));
            console.error('Error deploying template:', err);
        }
    };

    // Функция удаления шаблона
    const handleDeleteTemplate = async () => {
        try {
            setIsDeleting(true);
            await deleteTemplate(token, template.id);
            setIsDeleting(false);
            setDeleteConfirmOpen(false);
            if (onDeleted) {
                onDeleted(template.id);
            }
        } catch (err) {
            setIsDeleting(false);
            setError && setError(t('dashboard.templates.delete_error'));
            console.error('Error deleting template:', err);
        }
    };

    // Статус шаблона
    const getStatusBadge = () => {
        const statusConfig = {
            'ready': {
                color: 'bg-green-100 text-green-800',
                icon: <CheckCircle className="w-4 h-4 mr-1" />
            },
            'creating': {
                color: 'bg-blue-100 text-blue-800',
                icon: <Loader className="w-4 h-4 mr-1 animate-spin" />
            },
            'error': {
                color: 'bg-red-100 text-red-800',
                icon: <AlertTriangle className="w-4 h-4 mr-1" />
            }
        };

        const config = statusConfig[template.status] || statusConfig.error;

        return (
            <div className={`flex items-center px-2 py-1 rounded text-xs font-medium ${config.color}`}>
                {config.icon}
                {t(`dashboard.templates.status.${template.status}`)}
            </div>
        );
    };

    return (
        <div className="p-4 rounded-lg border border-gray-200 hover:border-blue-300 hover:shadow-sm transition-all bg-white">
            <div className="flex justify-between items-start">
                <div>
                    <h3 className="font-medium text-gray-900">
                        {template.name}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                        {new Date(template.created_at).toLocaleDateString()}
                    </p>
                </div>

                <div className="flex items-center gap-2">
                    <Tooltip title={t('dashboard.templates.deploy')}>
                        <IconButton
                            size="small"
                            color="success"
                            onClick={() => setDeployConfirmOpen(true)}
                        >
                            <Play className="w-5 h-5" />
                        </IconButton>
                    </Tooltip>

                    {isOwner && (
                        <>
                            <Tooltip title={t('dashboard.templates.share')}>
                                <IconButton
                                    size="small"
                                    color="info"
                                    onClick={() => setShareDialogOpen(true)}
                                >
                                    <Share2 className="w-5 h-5" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={t('dashboard.templates.delete')}>
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => setDeleteConfirmOpen(true)}
                                >
                                    <Trash2 className="w-5 h-5" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>

            <div className="flex flex-wrap gap-2 mt-3">
                {getStatusBadge()}

                <div className="flex items-center px-2 py-1 rounded text-xs font-medium bg-gray-100 text-gray-800">
                    <Server className="w-4 h-4 mr-1" />
                    {template.components_count || 0} {t('dashboard.templates.components')}
                </div>
            </div>

            {/* Метаданные шаблона */}
            <div className="mt-2 text-sm text-gray-500">
                {template.created_by && (
                    <p className="flex items-center mt-1">
                        <Avatar
                            sx={{ width: 20, height: 20, fontSize: '0.75rem', marginRight: '8px' }}
                            alt={template.created_by?.username || 'User'}
                        >
                            {template.created_by && template.created_by.username
                                ? template.created_by.username.charAt(0).toUpperCase()
                                : '?'}
                        </Avatar>
                        {t('dashboard.templates.author')}: {template.created_by.username}
                    </p>
                )}

                {template.sandbox_name && (
                    <p className="flex items-center mt-1">
                        <Server className="w-4 h-4 mr-2" />
                        {t('dashboard.templates.based_on')}: {template.sandbox_name}
                    </p>
                )}
            </div>

            {/* Диалог развертывания */}
            <Dialog
                open={deployConfirmOpen}
                onClose={() => !isDeploying && setDeployConfirmOpen(false)}
            >
                <DialogTitle>
                    {t('dashboard.templates.deploy_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.templates.deploy_confirm', { name: template.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeployConfirmOpen(false)}
                        disabled={isDeploying}
                    >
                        {t('dashboard.templates.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeployTemplate}
                        variant="contained"
                        color="success"
                        disabled={isDeploying}
                        startIcon={isDeploying ? <CircularProgress size={20} /> : <Play size={16} />}
                    >
                        {isDeploying
                            ? t('dashboard.templates.deploying')
                            : t('dashboard.templates.deploy')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог удаления */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={() => !isDeleting && setDeleteConfirmOpen(false)}
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.templates.delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.templates.delete_confirm', { name: template.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteConfirmOpen(false)}
                        disabled={isDeleting}
                    >
                        {t('dashboard.templates.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteTemplate}
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        startIcon={isDeleting ? <CircularProgress size={20} /> : <Trash2 size={16} />}
                    >
                        {isDeleting
                            ? t('dashboard.templates.deleting')
                            : t('dashboard.templates.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог шаринга */}
            {shareDialogOpen && (
                <ShareTemplateDialog
                    open={shareDialogOpen}
                    onClose={() => setShareDialogOpen(false)}
                    template={template}
                    token={token}
                    setError={setError}
                    onSuccess={() => setShareDialogOpen(false)}
                />
            )}
        </div>
    );
};

export default TemplateCard;