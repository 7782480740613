// src/components/dashboard/tabs/DashboardSandboxesTab.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Typography,
    TextField,
    InputAdornment,
    Tabs,
    Tab,
    CircularProgress,
    Box,
    Alert,
    Chip
} from '@mui/material';
import {
    Rocket,
    Search,
    Plus,
    BookTemplate,
    Server,
    Users,
    FileStack
} from 'lucide-react';
import {
    createSandboxComponent,
    deployTemplate,
    fetchActiveSandboxes,
    fetchEnvironmentTemplates, fetchInstanceByProcessId,
    startCamundaProcess, updateInstanceArchitecture, fetchComponentTypes
} from '../../../services/apiService';
import CreateSandboxDialog from '../../sandbox/CreateSandboxDialog';
import SandboxCard from './SandboxCard';
import TemplateCard from './TemplateCard';
import ShareSandboxDialog from './ShareSandboxDialog';
import GenerationLoader from '../../GenerationLoader';


const DashboardSandboxesTab = ({ userInfo, token, setError }) => {
    const { t } = useTranslation();

    // Состояния для данных
    const [sandboxes, setSandboxes] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [processId, setProcessId] = useState(null);
    const [sandboxId, setSandboxId] = useState(null);
    const [componentTypes, setComponentTypes] = useState([]);
    const [generationStarted, setGenerationStarted] = useState(false);

    // Состояния для фильтрации и поиска
    const [activeFilter, setActiveFilter] = useState(0); // 0 - все, 1 - мои, 2 - доступные
    const [searchTerm, setSearchTerm] = useState('');

    // Состояния для диалогов
    const [createSandboxDialogOpen, setCreateSandboxDialogOpen] = useState(false);
    const [shareSandboxDialogOpen, setShareSandboxDialogOpen] = useState(false);
    const [selectedSandbox, setSelectedSandbox] = useState(null);

    // Загрузка данных
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, userInfo]);

    // Функция загрузки данных
    const loadData = async () => {
        setIsLoading(true);
        try {
            // Загрузка типов компонентов
            const typesData = await fetchComponentTypes(token);
            setComponentTypes(typesData);

            // Загрузка песочниц
            const [ownedSandboxes, sharedSandboxes] = await Promise.all([
                fetchActiveSandboxes(token, userInfo.id, 'owner'),
                fetchActiveSandboxes(token, userInfo.id, 'coworker')
            ]);

            // Маркируем песочницы как собственные или доступные
            const markedOwnedSandboxes = ownedSandboxes.map(sb => ({...sb, isOwned: true, type: 'sandbox'}));
            const markedSharedSandboxes = sharedSandboxes.map(sb => ({...sb, isOwned: false, type: 'sandbox'}));

            setSandboxes([...markedOwnedSandboxes, ...markedSharedSandboxes]);

            // Загрузка шаблонов
            const allTemplates = await fetchEnvironmentTemplates(token);

            // Маркируем шаблоны как собственные или доступные
            const markedTemplates = allTemplates.map(template => ({
                ...template,
                isOwned: template.created_by && template.created_by.id === userInfo.id,
                type: 'template'
            }));

            setTemplates(markedTemplates);
        } catch (err) {
            setError(t('dashboard.sandboxes.error.loading_data'));
            console.error('Error loading data:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Обработчик изменения фильтра
    const handleFilterChange = (event, newValue) => {
        setActiveFilter(newValue);
    };

    // Функция фильтрации элементов
    const getFilteredItems = () => {
        // Объединяем песочницы и шаблоны в один список
        const allItems = [...sandboxes, ...templates];

        // Фильтруем по владельцу
        let filteredItems;

        if (activeFilter === 0) { // Все
            filteredItems = allItems;
        } else if (activeFilter === 1) { // Мои
            filteredItems = allItems.filter(item => item.isOwned);
        } else { // Доступные
            filteredItems = allItems.filter(item => !item.isOwned);
        }

        // Фильтрация по поисковому запросу
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            return filteredItems.filter(item => item.name.toLowerCase().includes(term));
        }

        return filteredItems;
    };

    // Обработчик создания песочницы
    const handleCreateSandbox = async (data) => {
        try {
            setError(null);
            setCreateSandboxDialogOpen(false);

            switch (data.type) {
                case 0: // По описанию
                    const response = await startCamundaProcess(
                        token,
                        data.prompt,
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(response.process_id);
                    setSandboxId(response.sandbox_id);
                    break;

                case 1: // Вручную
                    // Запускаем процесс в ручном режиме
                    const manualResponse = await startCamundaProcess(
                        token,
                        'manual',
                        userInfo.id,
                        'manual',
                        null
                    );
                    setProcessId(manualResponse.process_id);
                    setSandboxId(manualResponse.sandbox_id);

                    // Создаем архитектуру из выбранных компонентов
                    const architecture = {
                        backend_components: data.components.map(component => ({
                            name: component.name,
                            description: component.prompt,
                            type: componentTypes.find(t => t.id === component.type)?.name || '',
                            subtype: component.subtype
                        }))
                    };

                    // Сохраняем архитектуру в instance
                    const instance = await fetchInstanceByProcessId(token, manualResponse.process_id);
                    await updateInstanceArchitecture(token, instance.id, architecture);

                    // Создаем компоненты
                    for (const component of data.components) {
                        await createSandboxComponent(token, {
                            sandbox: manualResponse.sandbox_id,
                            name: component.systemName,
                            host: `https://sandbox.dvakota.com/${manualResponse.namespace}/${component.systemName}`,
                            port: 0,
                            type_name_input: componentTypes.find(t => t.id === component.type)?.name,
                            status: 'creating',
                            configuration: {
                                name: component.systemName,
                                description: component.prompt,
                                type: componentTypes.find(t => t.id === component.type)?.name,
                                subtype: component.subtype
                            },
                            description: component.prompt
                        });
                    }
                    break;

                case 2: // Из шаблона
                    const templateResponse = await deployTemplate(
                        token,
                        data.templateId
                    );
                    setProcessId(templateResponse.process_id);
                    setSandboxId(templateResponse.sandbox_id);
                    break;

                case 3: // Мне повезёт
                    const luckyResponse = await startCamundaProcess(
                        token,
                        'Случайная, но очень интересная для изучения система',
                        userInfo.id,
                        'base',
                        null
                    );
                    setProcessId(luckyResponse.process_id);
                    setSandboxId(luckyResponse.sandbox_id);
                    break;

                default:
                    setError(t('dashboard.error.unknown_sandbox_type'));
                    return;
            }

            setGenerationStarted(true);

            // Обновляем список песочниц после создания с небольшой задержкой
            setTimeout(() => {
                loadData();
                setGenerationStarted(false); // Сбрасываем флаг после завершения
            }, 2000);

        } catch (err) {
            setError(t('dashboard.sandboxes.error.creating'));
            console.error('Error creating sandbox:', err);
        }
    };

    // Обработчик удаления песочницы
    const handleDeleteSandbox = (sandboxId) => {
        setSandboxes(prev => prev.filter(s => s.id !== sandboxId));
    };

    // Обработчик удаления шаблона
    const handleDeleteTemplate = (templateId) => {
        setTemplates(prev => prev.filter(t => t.id !== templateId));
    };

    // Обработчик открытия диалога шаринга
    const handleOpenShareSandbox = (sandbox) => {
        setSelectedSandbox(sandbox);
        setShareSandboxDialogOpen(true);
    };

    // Рассчитываем количество элементов для бейджей
    const counts = {
        all: sandboxes.length + templates.length,
        owned: sandboxes.filter(s => s.isOwned).length + templates.filter(t => t.isOwned).length,
        shared: sandboxes.filter(s => !s.isOwned).length + templates.filter(t => !t.isOwned).length
    };

    // Получаем отфильтрованные элементы
    const filteredItems = getFilteredItems();

    // Цвета для иконок фильтров
    const filterIconColors = {
        all: "text-blue-500",
        owned: "text-green-500",
        shared: "text-purple-500"
    };

    return (
        <div className="space-y-6">
            {/* Панель действий с поиском и кнопкой создания */}
            <div className="flex flex-col md:flex-row gap-4 items-center mb-6">
                <TextField
                    placeholder={t('dashboard.sandboxes.search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="flex-grow"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search className="text-gray-400" />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    fullWidth
                />

                <button
                    onClick={() => setCreateSandboxDialogOpen(true)}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg flex items-center gap-3 h-10 whitespace-nowrap"
                >
                    <Plus className="w-4 h-4" />
                    <span>{t('dashboard.to_create_sandbox')}</span>
                </button>
            </div>

            {/* Фильтры */}
            <div className="rounded-lg overflow-hidden shadow-sm bg-white">
                <Tabs
                    value={activeFilter}
                    onChange={handleFilterChange}
                    variant="fullWidth"
                >
                    <Tab
                        icon={<FileStack className={`w-4 h-4 mr-2 ${filterIconColors.all}`} />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.sandboxes.filters.all')}
                                <Chip
                                    label={counts.all}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#e3f2fd', color: '#1976d2' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                    <Tab
                        icon={<Server className={`w-4 h-4 mr-2 ${filterIconColors.owned}`} />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.sandboxes.filters.owned')}
                                <Chip
                                    label={counts.owned}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#e8f5e9', color: '#2e7d32' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                    <Tab
                        icon={<Users className={`w-4 h-4 mr-2 ${filterIconColors.shared}`} />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.sandboxes.filters.shared')}
                                <Chip
                                    label={counts.shared}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#f3e5f5', color: '#7b1fa2' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                </Tabs>

                {/* Контент */}
                <div className="p-4">
                    {isLoading ? (
                        <div className="py-12 flex justify-center items-center">
                            <CircularProgress />
                            <Typography variant="body1" className="ml-4 text-gray-600">
                                {t('dashboard.loading')}
                            </Typography>
                        </div>
                    ) : filteredItems.length === 0 ? (
                        <Alert severity="info" className="my-6">
                            {t('dashboard.sandboxes.no_items_found')}
                        </Alert>
                    ) : (
                        <div>
                            {/* Заголовок песочниц, если есть песочницы в выборке */}
                            {filteredItems.some(item => item.type === 'sandbox') && (
                                <div>
                                    <Typography variant="h6" className="mb-4 mt-2 flex items-center">
                                        <Rocket className="w-5 h-5 mr-2 text-blue-500" />
                                        {t('dashboard.sandboxes.title')}
                                    </Typography>

                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
                                        {filteredItems.filter(item => item.type === 'sandbox').map(sandbox => (
                                            <SandboxCard
                                                key={sandbox.id}
                                                sandbox={sandbox}
                                                isOwner={sandbox.isOwned}
                                                onShare={() => handleOpenShareSandbox(sandbox)}
                                                onDelete={handleDeleteSandbox}
                                                token={token}
                                                setError={setError}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Заголовок шаблонов, если есть шаблоны в выборке */}
                            {filteredItems.some(item => item.type === 'template') && (
                                <div>
                                    <Typography variant="h6" className="mb-4 mt-6 flex items-center">
                                        <BookTemplate className="w-5 h-5 mr-2 text-green-500" />
                                        {t('dashboard.templates.title')}
                                    </Typography>

                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                        {filteredItems.filter(item => item.type === 'template').map(template => (
                                            <TemplateCard
                                                key={template.id}
                                                template={template}
                                                token={token}
                                                setError={setError}
                                                onDeleted={handleDeleteTemplate}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Диалоги */}
            <CreateSandboxDialog
                open={createSandboxDialogOpen}
                onClose={() => setCreateSandboxDialogOpen(false)}
                onSubmit={handleCreateSandbox}
                token={token}
                error={null}
                setError={setError}
                componentTypes={[]} // Здесь нужно передать настоящие типы компонентов
            />

            {selectedSandbox && (
                <ShareSandboxDialog
                    open={shareSandboxDialogOpen}
                    onClose={() => setShareSandboxDialogOpen(false)}
                    sandbox={selectedSandbox}
                    token={token}
                    setError={setError}
                    onSuccess={() => {
                        setShareSandboxDialogOpen(false);
                        loadData(); // Обновить данные после шаринга
                    }}
                />
            )}

            {/* Индикатор загрузки при создании песочницы */}
            {generationStarted && processId && sandboxId && (
                <GenerationLoader
                    token={token}
                    processId={processId}
                    sandboxId={sandboxId}
                />
            )}
        </div>
    );
};

export default DashboardSandboxesTab;